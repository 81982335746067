import React from "react";
import { Routes, Route } from "react-router-dom";
import Locations from "../pages/Locations/locations";
import WeeklyOperations from "../pages/Weekly-Operations/WeeklyOperations";
import NewWeeklyOperations from "../pages/new-weekly-operations/newWeeklyOperations";
import Layout from "../components/layouts/Layout";
import Marketing from "../domain/Marketing/pages/Marketing";
import DailyOperations from "../domain/DailyOperations/pages/DailyOperations";
import CfLocations from "../pages/Locations/CfLocations";
import Host from "./../pages/host/host";
import { FinanceAndAdmin } from "../pages/FinanceAndAdmin/FinanceAndAdmin";
import RoyalityCollected from "../pages/RoyalityCollected/RoyalityCollected";


const PagesRoutes = () => (
  <>
    <Routes>
      <Route
        exact
        path="/locations"
        element={
          <Layout>
            <Locations />
          </Layout>
        }
      />
      <Route
        exact
        path="/daily-operations"
        element={
          <Layout>
            <DailyOperations />
          </Layout>
        }
      />
      <Route
        exact
        path="/weekly-operations"
        element={
          <Layout>
            <WeeklyOperations />
          </Layout>
        }
      />

      <Route
        exact
        path="/cf-weekly-operations"
        element={
          <Layout>
            <CfLocations />
          </Layout>
        }
      />
      <Route
        exact
        path="/marketing"
        element={
          <Layout>
            <Marketing />
          </Layout>
        }
      />
       <Route
        exact
        path="/new-weekly-operations"
        element={
          <Layout>
            <NewWeeklyOperations />
          </Layout>
        }
      />
      <Route
        exact
        path="/host"
        element={
          <Layout>
            <Host />
          </Layout>
        }
      />
      <Route
        exact
        path="/finance-admin"
        element={
          <Layout>
           <FinanceAndAdmin/>
          </Layout>
        }
      />
      <Route
        exact
        path="/royality-collected"
        element={
          <Layout>
           <RoyalityCollected/>
          </Layout>
        }
      />
    </Routes>
  </>
);

export default PagesRoutes;
