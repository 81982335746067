import React from 'react'

const HighestEarningCard = ({earningData}) => {
    return (
        <div className=" shadow-[0px_4px_10px_rgba(0,0,0,0.15)] rounded-xl">
            <div className="p-0">
                <div className="py-4 flex flex-col gap-4">
                    <h2 className="px-4 text-gray-400 font-medium text-xl">Highest earning location</h2>
                    <p className="text-xl font-bold px-4 text-gray-900 ">
                        Basingstock
                        {/* £{currentEarnings.toLocaleString('en-GB', { minimumFractionDigits: 2 })} */}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HighestEarningCard