import React, { useState, useEffect, useRef } from "react";
import { CiCalendar } from "react-icons/ci";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  format,
  endOfWeek,
  subWeeks,
  addWeeks,
  isAfter,
  parseISO,
  startOfWeek,
  parse,
} from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { handleDateChange } from "../../../redux/slices/filter-slice";
import FinalDatePicker from "./FinalDatePicker";
import { CircularProgress } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ParentDatePicker = ({ id }) => {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const startVal = "";
  const endVal = "";

  // Get the current week's start (Monday) and end (Sunday)
  const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
  const currentWeekEnd = endOfWeek(new Date(), { weekStartsOn: 1 });

  // Format dates for Redux store
  const formattedCurrentStart = startVal
    ? format(startVal, "yyyy-MM-dd")
    : format(currentWeekStart, "yyyy-MM-dd");
  const formattedCurrentEnd = endVal
    ? format(endVal, "yyyy-MM-dd")
    : format(currentWeekEnd, "yyyy-MM-dd");

  // Access initial dates from the Redux store with default values
  const initialStartDate = useSelector(
    (state) => state.filter?.[id]?.start || formattedCurrentStart
  );
  const initialEndDate = useSelector(
    (state) => state.filter?.[id]?.end || formattedCurrentEnd
  );

  // Initialize state with Redux store values or current week
  const [startDate, setStartDate] = useState(initialStartDate); // initialStartDate
  const [endDate, setEndDate] = useState(initialEndDate); // initialEndDate
  const [isLoadingPrev, setIsLoadingPrev] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);

  // Format initial date range string
  const [selectedDateRange, setSelectedDateRange] = useState(
    `${format(parseISO(initialStartDate), "d MMM, yyyy")} - ${format(
      parseISO(initialEndDate),
      "d MMM, yyyy"
    )}`
  );

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  useEffect(() => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
    setSelectedDateRange(
      `${format(parseISO(initialStartDate), "d MMM, yyyy")} - ${format(
        parseISO(initialEndDate),
        "d MMM, yyyy"
      )}`
    );
  }, [initialStartDate, initialEndDate]);

  // Add this near the top of your component, after defining currentWeekStart
  const previousWeekStart = startOfWeek(subWeeks(new Date(), 1), {
    weekStartsOn: 1,
  });

  const moveToPreviousWeek = async () => {
    setIsLoadingPrev(true);
    setTimeout(async () => {
      try {
        const newStart = subWeeks(parseISO(startDate), 1);
        const newEnd = endOfWeek(newStart, { weekStartsOn: 1 });

        const formattedStartDate = format(newStart, "yyyy-MM-dd");
        const formattedEndDate = format(newEnd, "yyyy-MM-dd");

        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        setSelectedDateRange(
          `${format(newStart, "d MMM, yyyy")} - ${format(
            newEnd,
            "d MMM, yyyy"
          )}`
        );
        dispatch(
          handleDateChange({
            id,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          })
        );
      } finally {
        setIsLoadingPrev(false);
      }
    }, 1000);
  };

  const moveToNextWeek = async () => {
    if (isAfter(parseISO(startDate), subWeeks(previousWeekStart, 1))) {
      return; // Don't allow moving forward beyond the previous week
    }

    setIsLoadingNext(true);
    setTimeout(async () => {
      try {
        const newStart = addWeeks(parseISO(startDate), 1);
        const newEnd = endOfWeek(newStart, { weekStartsOn: 1 });

        const formattedStartDate = format(newStart, "yyyy-MM-dd");
        const formattedEndDate = format(newEnd, "yyyy-MM-dd");

        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        setSelectedDateRange(
          `${format(newStart, "d MMM, yyyy")} - ${format(
            newEnd,
            "d MMM, yyyy"
          )}`
        );

        await dispatch(
          handleDateChange({
            id,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          })
        );
      } finally {
        setIsLoadingNext(false);
      }
    }, 1000);
  };

  const toggleDatePicker = () => {
    setIsDatePickerVisible(!isDatePickerVisible);
  };

  const showErrorMessage = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleDateRangeSelect = (dateRange) => {
    console.log("handleDateRangeSelect", dateRange);
    const [start, end] = dateRange?.split(" - ");
    const newStartDate = new Date(start); 
    const newEndDate = end ? new Date(end) : null;

    // Check if only start date is selected (no end date)
    if (!end) {
      showErrorMessage("Please select both start and end dates!");
      return; // Return early without proceeding further
    }

    // Check if the new start date is after the previous week's start
    if (isAfter(newStartDate, previousWeekStart)) {
      return; // Don't allow selecting dates beyond the previous week
    }

    setSelectedDateRange(dateRange);
    setIsDatePickerVisible(false);

    const formattedStartDate = format(newStartDate, "yyyy-MM-dd");
    const formattedEndDate = format(newEndDate, "yyyy-MM-dd");

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);

    dispatch(
      handleDateChange({
        id,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      })
    );
  };

  const handleReset = (resetDate) => {
    console.log("handleReset", resetDate);

    const [start, end] = resetDate.split(" - ");

    const parsedStartDate = parse(start, "d MMM, yyyy", new Date());
    const parsedEndDate = parse(end, "d MMM, yyyy", new Date());

    const formattedStartDate = format(parsedStartDate, "yyyy-MM-dd");
    const formattedEndDate = format(parsedEndDate, "yyyy-MM-dd");

    // const formattedStartDate = formattedCurrentStart;
    // const formattedEndDate = formattedCurrentEnd;

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    setSelectedDateRange(
      `${format(formattedStartDate, "d MMM, yyyy")} - ${format(
        formattedEndDate,
        "d MMM, yyyy"
      )}`
    );

    dispatch(
      handleDateChange({
        id,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      })
    );
  };

  const handleRangeChange = (val) => {
    handleDateRangeSelect(val);
    console.log("handleRangeChange", val);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsDatePickerVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative flex items-center gap-2">
      <div className="flex items-center gap-4">
        <button
          className="text-md rounded text-gray-400"
          onClick={moveToPreviousWeek}
          disabled={isLoadingPrev}
        >
          {isLoadingPrev ? (
            <CircularProgress color="success" size={20} />
          ) : (
            <FaChevronLeft color="#333B48" />
          )}
        </button>
        <span className="text-sm font-semibold text-primary-7">
          {selectedDateRange}
        </span>
        <button
          className={`text-md rounded text-gray-400  ${
            isAfter(parseISO(startDate), subWeeks(previousWeekStart, 1))
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          onClick={moveToNextWeek}
          disabled={
            isLoadingNext ||
            isAfter(parseISO(startDate), subWeeks(previousWeekStart, 1))
          }
        >
          {isLoadingNext ? (
            <CircularProgress color="success" size={20} />
          ) : (
            <FaChevronRight color="#333B48" />
          )}
        </button>
      </div>

      <div
        className="bg-white border-2 border-primary-5 px-2 py-2 rounded-lg flex items-center cursor-pointer w-10 mr-4"
        style={{margin: "5px"}}
        onClick={toggleDatePicker}
      >
        <CiCalendar size={20} />
      </div>

      {isDatePickerVisible && (
        <div className="absolute right-0 top-10 w-72 z-50" ref={modalRef}>
          <FinalDatePicker
            onDateRangeSelect={handleRangeChange}
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            onReset={handleReset}
            onChange={handleRangeChange}
          />
        </div>
      )}

      {/* ToastContainer to display toast messages */}
      <ToastContainer />
    </div>
  );
};

export default ParentDatePicker;
