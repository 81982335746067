import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import React, { useState, useRef } from 'react';

const GlobalDropdown = ({
    items = [],
    onSelect,
    placeholder = "Select",
    width = 100,
    menuWidth = 150,
    dropdownHeight = 35,
    customBorderColor = "#D7DEE8",
    customBorderWidth = 1,
    left = 0,
    menuHeight = 200,
    disable, // Disable prop
    selectedYear,
    setSelectedYear,
    initialYear,
    setInitialYear,
    secondYear,
    setSecondYear,
}) => {
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(selectedYear || initialYear || secondYear);
    const menuRef = useRef(null);

    const handleToggle = () => {
        if (!disable) {
            setOpen(!open);
        }
    };

    const handleClickAway = () => setOpen(false);

    const handleSelect = (item) => {
        if (!disable) {
            const value = item.value || item;
            const label = item.label || item;

            // Update initialYear if setInitialYear exists
            if (setInitialYear) {
                setInitialYear(label);
            }

            // Only update secondYear if setSecondYear exists
            if (setSecondYear) {
                setSecondYear(label);
            }

            // Update selectedYear if setSelectedYear exists
            if (setSelectedYear) {
                setSelectedYear(label);
            }
            setSelectedValue(label);
            setOpen(false);
            if (onSelect) {
                onSelect(value);
            }
        }
    };

    const renderValue = () => {
        if (!selectedValue) {
            return placeholder;
        }
        return selectedValue;
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="relative" style={{ width }}>
                <div
                    className={`hover:border-orange-400 border-solid transition-colors duration-200 ${disable ? "cursor-not-allowed bg-gray-200 " : "cursor-pointer text-gray-200"
                        }`}
                    onClick={handleToggle}
                    style={{
                        height: dropdownHeight,
                        border: `${customBorderWidth}px solid ${customBorderColor}`,
                        display: "flex",
                        alignItems: "center",
                        padding: "0 8px",
                        justifyContent: "space-between",
                        backgroundColor: disable ? "#f5f5f5" : "white", // Gray background if disabled
                        // color: !selectedValue ? (disable ? "lightgray" : "gray") : "inherit",
                        color: disable ? "gray" : "inherit",
                        borderRadius: "8px",
                        
                    }}
                >
                    <span className="truncate">{renderValue()}</span>
                    <KeyboardArrowDownIcon
                        className={`transform transition-transform duration-200 ${open ? "rotate-180" : ""
                            }`}
                    />
                </div>

                {open && !disable && ( // Prevent rendering dropdown if disabled
                    <div
                        ref={menuRef}
                        className="absolute w-full mt-2 bg-white border border-gray-200 rounded-lg shadow-lg overflow-y-auto custom-scrollbar"
                        style={{
                            top: "100%",
                            left: left,
                            width: menuWidth,
                            maxHeight: menuHeight,
                            zIndex: 1000,
                        }}
                    >
                        {items.map((item, index) => (
                            <div
                                key={index}
                                className="px-3 py-2 hover:bg-gray-50 cursor-pointer transition-colors duration-150"
                                onClick={() => handleSelect(item)}
                            >
                                {item.label || item}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};

export default GlobalDropdown;
