import React, { useState, useMemo, useEffect } from "react";
import { Star, Eye } from "react-feather";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { SadEmoji } from "./icons";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useCallPostMutation, useCallGetQuery } from "./../services/api";


import {
  handleDateChange,
  setSelectedLocation,
  setweeklyOperationsData,
} from "../redux/slices/filter-slice";

const globalStatus = {
  1: "Active",
  2: "Trial",
  3: "Churned",
};

export const CFTable = ({ tableData, loading }) => {
  function getColorCode(op_score) {
    if (op_score >= 85) return "Dark Green";
    else if (op_score >= 75 && op_score <= 84) return "Light Green";
    else if (op_score >= 50 && op_score < 75) return "Amber";
    else return "Red";
  }

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const primary6 = "#515F6F";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationDate = useSelector((state) => state?.filter?.locationDates);
  const [postData, { isLoading: isPosting }] = useCallPostMutation();

  const handleFetchWeeklyOperationsData = async () => {
    try {
      const weeklyRollingRatings = await postData({
        url: "weekly-ops/get-rolling-ratings",
        body: {
          weekStart: locationDate.start
        },
      });
    
      if (weeklyRollingRatings?.data?.status) {
        if(!weeklyRollingRatings?.data?.data?.current_avg_rating){
          const startDate = new Date(locationDate.start);
          var previousDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);
          previousDate = previousDate.toISOString().split('T')[0];
          console.error("Null", locationDate.start, previousDate)
          dispatch(handleDateChange({
            id: "locationDates",
            startDate: previousDate,
            endDate: locationDate.start,
          }))
        }else{
          // setWeeklyRollingRating(weeklyRollingRatings?.data?.data);
        }
      }
    }
    catch (err) {
      console.error(err.message);
    }
  }


  useEffect(() => {
    handleFetchWeeklyOperationsData();
  }, [locationDate]);

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });

    let formattedAmount = formatter.format(amount);
    formattedAmount = formattedAmount.replace(/^£/, "£");
    return formattedAmount;
  };

  const handleClickNavigate = (location, SelectedData) => {
    dispatch(
      handleDateChange({
        id: "weeklyDates",
        startDate: SelectedData.start,
        endDate: SelectedData.end,
      })
    );
    dispatch(setweeklyOperationsData([location.location]));
    dispatch(setSelectedLocation([location.location]));

    navigate(`/new-weekly-operations?redirect=true`);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedTableData = useMemo(() => {
    const sortableData = [...tableData];
    if (sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        // Check if the sorting key is "weekly_sales" or "rating" to handle numeric string values
        if (
          sortConfig.key === "weekly_sales" ||
          sortConfig.key === "rating" ||
          sortConfig.key === "op_score"
        ) {
          // Convert rating or weekly_sales to numbers for proper numeric sorting
          const aValue = parseFloat(a[sortConfig.key]);
          const bValue = parseFloat(b[sortConfig.key]);
          return sortConfig.direction === "asc"
            ? aValue - bValue
            : bValue - aValue;
        } else {
          // Default string comparison for other fields
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableData;
  }, [tableData, sortConfig]);

  const renderTableContent = () => {
    if (loading) {
      return Array.from({ length: 10 }).map((_, index) => (
        <tr key={index} className="bg-white border-b hover:bg-gray-50">
          <td className="px-2 sm:px-4 py-2 sm:py-3 w-10">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden sm:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden md:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden lg:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden xl:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3">
            <Skeleton circle width={20} height={20} />
          </td>
        </tr>
      ));
    }

    if (!loading && (sortedTableData.length === 0 || tableData.length === 0)) {
      return (
        <tr className="bg-gray-50 roboto-font-table">
          <td
            colSpan="6"
            className="px-2 sm:px-4 py-12 sm:py-24 text-center roboto-font-table "
          >
            <div className="flex flex-col items-center justify-center">
              <SadEmoji />
              <p className="text-gray-900 font-semibold text-lg sm:text-xl">
                No records found
              </p>
              <p className="text-gray-500 mt-2 text-sm sm:text-base roboto-font-table ">
                Try adjusting your search or filter to find what you're looking
                for.
              </p>
            </div>
          </td>
        </tr>
      );
    }

    // return sortedTableData.map((row, index) => {
    //     return (
    //         <>
    //             <tr
    //                 key={index}
    //                 className="bg-white border-t border-b hover:bg-gray-50 roboto-font-table "
    //             >
    //                 <td
    //                     className={`truncate-text px-2 sm:px-4 py-2 sm:py-3 text-primary-7 text-base font-normal roboto-font-table ${parseInt(row?.status) === 2
    //                             ? "text-orange-600"
    //                             : parseInt(row?.status) === 1
    //                                 ? "text-primary-7"
    //                                 : "text-primary-6"}`}
    //                     data-tooltip-id="location-tooltip" // Changed attribute
    //                     data-tooltip-content={row?.location} // Changed attribute
    //                 >
    //                     <div
    //                         className=" justify-center gap-2 cursor-pointer roboto-font-table "
    //                         onClick={() => {
    //                             handleClickNavigate(row, {
    //                                 start: locationDate?.start,
    //                                 end: locationDate?.end,
    //                             });
    //                         } }
    //                     >
    //                         {row?.Name}
    //                     </div>
    //                 </td>

    //                 <td
    //                     className="px-2 sm:px-4 py-2 sm:py-3 hidden sm:table-cell text-primary-7 text-base font-normal roboto-font-table "
    //                     data-tooltip-id="Weekly-tooltip" // Changed attribute
    //                     data-tooltip-content={`Overall Sales: ${formatCurrency(row?.Sales)}`} // Changed attribut
    //                 >
    //                     {/* {JSON.stringify(row)} */}
    //                     {formatCurrency(row?.Sales)}
    //                 </td>
    //                 {/* <td
    //                     className={`px-2 sm:px-4 py-2 sm:py-3 hidden md:table-cell roboto-font-table ${parseInt(row?.status) === 2
    //                             ? "text-orange-600 text-base font-medium"
    //                             : parseInt(row?.status) === 1
    //                                 ? `text-[#12B366] text-base font-medium`
    //                                 : `text-${primary6} text-base font-medium`}`}
    //                 >
    //                     {globalStatus[row?.status]}
    //                     N/A
    //                 </td> */}
    //                 <td className="px-2 sm:px-4 py-2 sm:py-3 hidden lg:table-cell text-base font-medium roboto-font-table ">
    //                     <div
    //                         className={`${row?.Rating >= 4.7
    //                                 ? "rounded-oval-dark-green"
    //                                 : row?.Rating >= 4.4 && row?.Rating < 4.7
    //                                     ? "rounded-oval-light-green"
    //                                     : row?.Rating >= 4.0 && row?.Rating < 4.4
    //                                         ? "rounded-oval-amber"
    //                                         : "rounded-oval-red"}`}
    //                     >
    //                         {row?.Rating !== undefined &&
    //                             row?.Rating !== "" &&
    //                             !isNaN(row?.Rating)
    //                             ? parseFloat(row?.Rating).toFixed(1)
    //                             : "0.0"}
    //                         {row?.Rating !== "-" && (
    //                             <Star
    //                                 size={18}
    //                                 className="font-semibold bold-star-icon"
    //                                 color={row?.Rating >= 4.7
    //                                     ? "#0C4E28" // Dark green
    //                                     : row?.Rating >= 4.4 && row?.Rating < 4.7
    //                                         ? "#12B366" // Light green
    //                                         : row?.Rating >= 4.0 && row?.Rating < 4.4
    //                                             ? "#FFC107" // Amber
    //                                             : "#B30625" // Red
    //                                 } />
    //                         )}
    //                     </div>
    //                 </td>
    //                 <td
    //                     className="px-2 sm:px-4 py-2 sm:py-3 hidden xl:table-cell  roboto-font-table "
    //                     data-tooltip-id={`op-score-${row.Name}`} // Make the tooltip ID unique
    //                 >
    //                     <div
    //                         className={`text-base font-medium ${getColorCode(parseInt(row?.Ops_score)) === "Dark Green"
    //                                 ? "rounded-oval-dark-green" // Dark Green
    //                                 : getColorCode(parseInt(row?.Ops_score)) === "Light Green"
    //                                     ? "rounded-oval-light-green" // Light Green
    //                                     : getColorCode(parseInt(row?.Ops_score)) === "Amber"
    //                                         ? "rounded-oval-amber" // Amber
    //                                         : "rounded-oval-red" // Red
    //                             }`}
    //                     >
    //                         {parseInt(row?.Ops_score) + "%"}
    //                     </div>

    //                     {/* Tooltip component for rendering tooltip */}
    //                     <Tooltip id={`op-score-${row.Name}`} place="bottom">
    //                         <div>
    //                             Overall Rider Waiting Time: {row?.Avg_rider_waiting_time}{" "}
    //                             mins
    //                         </div>
    //                         <div>Overall Open Rate: {row?.Open_rate_percent}%</div>
    //                         <div>Inaccurate Orders: {row?.Inaccurate_orders_percent}%</div>
    //                         <div>Number of Orders: {row?.Number_of_orders} </div>
    //                     </Tooltip>
    //                 </td>

    //                 <td className="px-2 sm:px-4 py-2 sm:py-3 roboto-font-table ">
    //                     {/* <button
    //                         className=" justify-center gap-2"
    //                         onClick={() => {
    //                             handleClickNavigate(row, {
    //                                 start: locationDate?.start,
    //                                 end: locationDate?.end,
    //                             });
    //                         } }
    //                     >
    //                         <span className="text-primary-7 text-sm font-semibold hidden sm:inline hover:underline">
    //                             View
    //                         </span>
    //                     </button> */}
    //                     N/A
    //                 </td>
    //             </tr>
    //         </>
    //     );
    // });
  

    return sortedTableData.map((row, index) => {
        // Helper function to handle Rating display
        const formatRating = (rating) => {
            if (rating === null || rating === undefined || rating === "" || isNaN(rating)) {
                return "0.0";
            }
            return parseFloat(rating).toFixed(1);
        };
    
        // Helper function to handle Ops_Score display
        const formatOpsScore = (score) => {
            if (score === null || score === undefined || score === "" || isNaN(score)) {
                return "0";
            }
            return parseInt(score);
        };
    
        // Get Rating color based on value
        const getRatingColor = (rating) => {
            const numRating = parseFloat(rating);
            if (isNaN(numRating) || numRating === 0) return "#B30625"; // Red for 0 or invalid
            if (numRating >= 4.7) return "#0C4E28";     // Dark green
            if (numRating >= 4.4) return "#12B366";     // Light green
            if (numRating >= 4.0) return "#FFC107";     // Amber
            return "#B30625";                           // Red
        };
    
        return (
            <tr
                key={index}
                className="bg-white border-t border-b hover:bg-gray-50 roboto-font-table"
            >
                {/* Name cell remains unchanged */}
                <td
                    className={`truncate-text px-2 sm:px-4 py-2 sm:py-3 text-primary-7 text-base font-normal roboto-font-table ${parseInt(row?.status) === 2
                        ? "text-orange-600"
                        : parseInt(row?.status) === 1
                            ? "text-primary-7"
                            : "text-primary-6"}`}
                    data-tooltip-id="location-tooltip"
                    data-tooltip-content={row?.location}
                >
                    <div
                        className="justify-center gap-2 cursor-pointer roboto-font-table"
                        onClick={() => {
                            handleClickNavigate(row, {
                                start: locationDate?.start,
                                end: locationDate?.end,
                            });
                        }}
                    >
                        {
                          row?.location?.split(" - ")[1]
                        }
                    </div>
                </td>
    
                {/* Sales cell remains unchanged */}
                <td
                    className="px-2 sm:px-4 py-2 sm:py-3 hidden sm:table-cell text-primary-7 text-base font-normal roboto-font-table"
                    data-tooltip-id="Weekly-tooltip"
                    data-tooltip-content={`Overall Sales: ${formatCurrency(row?.weekly_sales)}`}
                >
                    {formatCurrency(row?.weekly_sales)}
                </td>
    
                {/* Rating cell updated */}
                <td className="px-2 sm:px-4 py-2 sm:py-3 hidden lg:flex justify-around text-base font-medium roboto-font-table">
                    <div
                        className={`${formatRating(row?.rating) >= 4.7
                            ? "rounded-oval-dark-green"
                            : formatRating(row?.rating) >= 4.4
                                ? "rounded-oval-light-green"
                                : formatRating(row?.rating) >= 4.0
                                    ? "rounded-oval-amber"
                                    : "rounded-oval-red"}`}
                    >
                        {formatRating(row?.rating)}
                        <Star
                            size={18}
                            className="font-semibold bold-star-icon"
                            color={getRatingColor(row?.rating)}
                        />
                    </div>
                </td>
    
                {/* Ops Score cell updated */}
                <td
                    className="px-2 sm:px-4 py-2 sm:py-3 hidden xl:table-cell roboto-font-table"
                    data-tooltip-id={`op-score-${row.location}`}
                >
                    <div
                        className={`text-base font-medium ${
                            getColorCode(formatOpsScore(row?.op_score)) === "Dark Green"
                                ? "rounded-oval-dark-green"
                                : getColorCode(formatOpsScore(row?.op_score)) === "Light Green"
                                    ? "rounded-oval-light-green"
                                    : getColorCode(formatOpsScore(row?.op_score)) === "Amber"
                                        ? "rounded-oval-amber"
                                        : "rounded-oval-red"
                        }`}
                    >
                        {formatOpsScore(row?.op_score) + "%"}
                    </div>
    
                    <Tooltip id={`op-score-${row.location}`} place="bottom">
                        <div>
                            Overall Rider Waiting Time: {row?.overall_rider_waiting_time ? Number(row.overall_rider_waiting_time).toFixed(1) : "0"} mins
                        </div>
                        <div>Overall Open Rate: {row?.overall_openrate ? Number(row.overall_openrate).toFixed(1) : "0"}%</div>
                        <div>Inaccurate Orders: {row?.inaccurate_order ? Number(row.inaccurate_order).toFixed(1) : "0"}%</div>
                        <div>Number of Orders: {row?.overall_number_of_orders || "0"}</div>
                    </Tooltip>
                </td>
    
                <td className="px-2 sm:px-4 py-2 sm:py-3 roboto-font-table">
                  <button
                      className=" justify-center gap-2"
                      onClick={() => {
                        handleClickNavigate(row, {
                          start: locationDate?.start,
                          end: locationDate?.end,
                        });
                      }}
                    >
                      <span className="text-primary-7 text-sm font-semibold hidden sm:inline hover:underline">
                        View
                      </span>
                    </button>
                </td>
            </tr>
        );
    });
};

  return (
    <div className="w-full overflow-x-auto max-h-auto custom-scrollbar roboto-font-table">
      <table className="table-fixed min-w-full divide-y divide-gray-200 sticky top-0 roboto-font-table">
        <thead className="bg-primary-2 sticky top-0 ">
          <tr className="roboto-font-table">
            <th
              onClick={() => handleSort("location")}
              className="roboto-font-table cursor-pointer px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md  relative  text-primary-6  text-base font-medium "
            >
              Location
              {sortConfig.key === "location" &&
                (sortConfig.direction === "asc" ? (
                  <IoIosArrowUp
                    className="absolute top-[17px] right-12"
                    size={15}
                  />
                ) : (
                  <IoIosArrowDown
                    className="absolute top-[17px] right-12"
                    size={15}
                  />
                ))}
            </th>

            <th
              onClick={() => handleSort("weekly_sales")}
              className="roboto-font-table cursor-pointer px-5 py-3  bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium relative"
            >
              Weekly Sales
              {sortConfig.key === "weekly_sales" &&
                (sortConfig.direction === "asc" ? (
                  <IoIosArrowUp
                    className="absolute top-[17px] right-6"
                    size={15}
                  />
                ) : (
                  <IoIosArrowDown
                    className="absolute top-[17px] right-6"
                    size={15}
                  />
                ))}
            </th>

            {/* <th
              onClick={() => handleSort("status")}
              className="roboto-font-table cursor-pointer px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium relative"
            >
              Status
              {sortConfig.key === "status" && (
                <>
                  {sortConfig.direction === "asc" ? (
                    <IoIosArrowUp
                      className="absolute top-[17px] right-2"
                      size={15}
                    />
                  ) : (
                    <IoIosArrowDown
                      className="absolute top-[17px] right-2"
                      size={15}
                    />
                  )}
                </>
              )}
            </th> */}
            <th
              onClick={() => handleSort("rating")}
              className="cursor-pointer roboto-font-table px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium relative"
            >
              Weekly Ratings
              {sortConfig.key === "rating" && (
                <span>
                  {sortConfig.direction === "asc" ? (
                    <IoIosArrowUp
                      className="absolute top-[17px] right-2"
                      size={15}
                    />
                  ) : (
                    <IoIosArrowDown
                      className="absolute top-[17px] right-2"
                      size={15}
                    />
                  )}
                </span>
              )}
            </th>
            <th
              onClick={() => handleSort("op_score")}
              className=" cursor-pointer roboto-font-table  px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium relative"
            >
              OP Score
              {sortConfig.key === "op_score" && (
                <span>
                  {sortConfig.direction === "asc" ? (
                    <IoIosArrowUp
                      className="absolute top-[17px] right-6"
                      size={15}
                    />
                  ) : (
                    <IoIosArrowDown
                      className="absolute top-[17px] right-6"
                      size={15}
                    />
                  )}
                </span>
              )}
            </th>
            <th className="cursor-pointer roboto-font-table px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium">
              <Eye size={16} />
            </th>
          </tr>
        </thead>
        <tbody>
          {renderTableContent()}
          <Tooltip id="location-tooltip" />
          <Tooltip id="Weekly-tooltip" />
          <Tooltip id="op-score" />
        </tbody>
      </table>
    </div>
  );
};
