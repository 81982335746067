import { Card, CardContent } from '@mui/material';
import React, { useState } from 'react';
import { Line, LineChart, ResponsiveContainer, Tooltip, Area } from 'recharts';

const AnnualTotalEarning = ({ data, onYearChange,selectedYear }) => {
    
    const currentEarnings = data.totalEarnings.find(
        item => item.year === selectedYear
    )?.earnings || 0;

    return (
        <div className="w-[400px] shadow-[0px_4px_10px_rgba(0,0,0,0.15)] rounded-xl">
            <div className="p-0">
                <div className="px-4 py-4">
                    <h2 className="text-gray-400 font-medium text-xl">Annual total earnings ({selectedYear})</h2>
                    <p className="text-2xl font-bold text-gray-900">
                        £{currentEarnings.toLocaleString('en-GB', { minimumFractionDigits: 2 })}
                    </p>
                </div>

            </div>
            <div className="h-32">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={data.totalEarnings}
                        margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
                    >
                        <defs>
                            <linearGradient id="gradientArea" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="#E879F9" stopOpacity={0.4} />
                                <stop offset="95%" stopColor="#E879F9" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <Tooltip
                            content={({ active, payload }) => {
                                if (active && payload && payload.length) {
                                    return (
                                        <div className="bg-white border rounded shadow-sm p-2">
                                            <p className="text-sm font-medium">
                                                £{payload[0].value.toLocaleString('en-GB', { minimumFractionDigits: 2 })}
                                            </p>
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Area
                            type="monotone"
                            dataKey="earnings"
                            stroke="none"
                            fillOpacity={1}
                            fill="url(#gradientArea)"
                        />
                        <Line
                            type="monotone"
                            dataKey="earnings"
                            stroke="#E879F9"
                            strokeWidth={3}
                            dot={false}
                            activeDot={{ r: 4, fill: "#E879F9" }}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default AnnualTotalEarning;