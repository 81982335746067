import React, { useState } from 'react'
import SearchBar from '../../../../components/global-components/Searchbar'
import Skeleton from 'react-loading-skeleton';
import { SadEmoji } from '../../../../components/icons';
import { Star } from 'react-feather';


const PerformanceTable = ({ data, loading }) => {



    const [error, setError] = useState(false);

    function getColorCode(op_score) {
        if (op_score >= 85) return "Dark Green";
        else if (op_score >= 75 && op_score <= 84) return "Light Green";
        else if (op_score >= 50 && op_score < 75) return "Amber";
        else return "Red";
    }


    const renderTableContent = () => {
        if (error) {
            return (
                <tr className="bg-gray-50">
                    <td colSpan="6" className="px-4 py-12 text-center">
                        <div className="flex flex-col items-center justify-center">
                            <SadEmoji />
                            <p className="font-semibold text-lg">Error loading data</p>
                            <p className="text-gray-500 mt-2 text-sm">
                                {error}. Please try again later.
                            </p>
                        </div>
                    </td>
                </tr>
            );
        }

        if (loading) {
            return Array.from({ length: 10 }).map((_, index) => (
                <tr key={index} className="bg-white border-b hover:bg-gray-50">
                    <td className="px-2 sm:px-4 py-2 sm:py-3 w-10">
                        <Skeleton />
                    </td>
                    <td className="px-2 sm:px-4 py-2 sm:py-3 hidden sm:table-cell">
                        <Skeleton />
                    </td>
                    <td className="px-2 sm:px-4 py-2 sm:py-3 hidden md:table-cell">
                        <Skeleton />
                    </td>
                    <td className="px-2 sm:px-4 py-2 sm:py-3 hidden lg:table-cell">
                        <Skeleton />
                    </td>
                    {/* <td className="px-2 sm:px-4 py-2 sm:py-3 hidden xl:table-cell">
                    <Skeleton />
                </td> */}
                    <td className="px-2 sm:px-4 py-2 sm:py-3">
                        <Skeleton circle width={20} height={20} />
                    </td>
                    <td className="px-2 sm:px-4 py-2 sm:py-3">
                        <Skeleton circle width={20} height={20} />
                    </td>
                </tr>
            ));
        }

        if (!loading && data.length === 0) {
            return (
                <tr className="bg-gray-50 roboto-font-table">
                    <td
                        colSpan="6"
                        className="px-2 sm:px-4 py-12 sm:py-24 text-center roboto-font-table "
                    >
                        <div className="flex flex-col items-center justify-center">
                            <SadEmoji />
                            <p className="text-gray-900 font-semibold text-lg sm:text-xl">
                                No records found
                            </p>
                            <p className="text-gray-500 mt-2 text-sm sm:text-base roboto-font-table ">
                                Try adjusting your search or filter to find what you're looking
                                for.
                            </p>
                        </div>
                    </td>
                </tr>
            );
        }

        return data.map((item, index) => (
            <tr key={index} className="hover:bg-gray-50">
                <td className="px-4 py-3 text-sm text-gray-900">{item.location}</td>
                <td className="px-4 py-3 text-sm text-gray-900">${item.annualEarnings}</td>
                <td className="px-4 py-3 text-sm text-gray-900">
                    <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2">
                        <p>{item.highestEarningMonth.month}</p>
                        <p className="text-sm text-gray-500">(${item.highestEarningMonth.earnings})</p>
                    </div>
                </td>
                <td className="px-4 py-3 text-sm text-gray-900">
                    <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2">
                        <p>{item.lowestEarningMonth.month}</p>
                        <p className="text-sm text-gray-500">(${item.lowestEarningMonth.earnings})</p>
                    </div>
                </td>
                <td className="px-4 py-3 text-sm text-gray-900">
                    <div
                        className={`${item.rating.toFixed(1) >= 4.7
                            ? "rounded-oval-dark-green"
                            : item.rating.toFixed(1) >= 4.4 && item.rating.toFixed(1) < 4.7
                                ? "rounded-oval-light-green"
                                : item.rating.toFixed(1) >= 4.0 && item.rating.toFixed(1) < 4.4
                                    ? "rounded-oval-amber"
                                    : "rounded-oval-red"
                            }`}
                    >
                        <p className='font-bold flex flex-row gap-[1px] items-center'>
                            {item.rating.toFixed(1) !== undefined &&
                                item.rating.toFixed(1) !== "" &&
                                !isNaN(item.rating.toFixed(1))
                                ? parseFloat(item.rating.toFixed(1)).toFixed(1)
                                : "0.0"}
                            {item.rating.toFixed(1) !== "-" && (
                                <Star
                                    size={18}
                                    className="font-semibold bold-star-icon"
                                    color={
                                        item.rating.toFixed(1) >= 4.7
                                            ? "#0C4E28" // Dark green
                                            : item.rating.toFixed(1) >= 4.4 && item.rating.toFixed(1) < 4.7
                                                ? "#12B366" // Light green
                                                : item.rating.toFixed(1) >= 4.0 && item.rating.toFixed(1) < 4.4
                                                    ? "#FFC107" // Amber
                                                    : "#B30625" // Red
                                    }
                                />
                            )}
                        </p>
                    </div>
                </td>
                <td className="px-4 py-3 text-sm text-gray-900">
                    <div
                        className={`text-base font-medium ${getColorCode(parseInt(item?.opScore)) === "Dark Green"
                            ? "rounded-oval-dark-green" // Dark Green
                            : getColorCode(parseInt(item?.opScore)) === "Light Green"
                                ? "rounded-oval-light-green" // Light Green
                                : getColorCode(parseInt(item?.opScore)) === "Amber"
                                    ? "rounded-oval-amber" // Amber
                                    : "rounded-oval-red" // Red
                            }`}
                    >
                        <p className='font-bold'>

                            {item?.opScore}%
                        </p>
                    </div>
                </td>
            </tr>
        ));

    };


    return (
        <div className="w-full h-[500px] overflow-y-auto">
            <table className="w-full border-t h-full">
                <thead className="bg-gray-50 border-b">
                    <tr>
                        <th className="px-4 py-3 text-sm font-medium text-gray-500 text-left">location</th>
                        <th className="px-4 py-3 text-sm font-medium text-gray-500 text-left">Annual earnings</th>
                        <th className="px-4 py-3 text-sm font-medium text-gray-500 text-left">Highest earning month</th>
                        <th className="px-4 py-3 text-sm font-medium text-gray-500 text-left">Lowest earning month</th>
                        <th className="px-4 py-3 text-sm font-medium text-gray-500 text-left">Rating</th>
                        <th className="px-4 py-3 text-sm font-medium text-gray-500 text-left">OP score</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {renderTableContent()}
                </tbody>
            </table>
        </div>
    )
}

export default PerformanceTable