import React, { useEffect, useState } from 'react'
import { PiCrownSimpleBold } from 'react-icons/pi'
import Toggle from '../../components/shared/Toggle'
import { Line } from 'react-chartjs-2'
import AnnualTotalEarning from './components/Cards/AnnualTotalEarningCard'
import HighestEarningCard from './components/Cards/HighestEarningCard'
import LowestEarningCard from './components/Cards/LowestEarningCard'
import EarningByPlatformCard from './components/Cards/EarningByPlatformCard'
import { useCallPostMutation } from '../../services/api'
import { useSelector } from 'react-redux'
import { earningsData, TableData } from './components/Cards/data'
import CustomDropdownCheckbox from '../../components/global-components/CustomDropdownChekbox'
import GloabalDropdown from '../../components/global-components/GloabalDropdown'
import YearlyComparisonChart from './components/Tables/YearlyComparisonChart'
import PerformanceTable from './components/Tables/PerformanceTable'
import SearchBar from '../../components/global-components/Searchbar'
import Pagination from "../../components/global-components/Pagination";
import GlobalDropdown from '../../components/global-components/GloabalDropdown'




const RoyalityCollected = () => {
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("Top 25%");
    const [postData, { isLoading: isPosting }] = useCallPostMutation();
    const ReduxSelectedLocations = useSelector(
        (state) => state.filter.selectedLocations
    );
    const [selectedYear, setSelectedYear] = useState(
        earningsData.totalEarnings[earningsData.totalEarnings.length - 1].year
    );
    const tabs = ["Top 25%", "26 - 50%", "51 - 75%", "Bottom 25%"];
    const [years, setYears] = useState([]);
    const [initialYear, setInitialYear] = useState(earningsData.totalEarnings[earningsData.totalEarnings.length - 1].year);
    const [secondYear, setSecondYear] = useState(earningsData.totalEarnings[earningsData.totalEarnings.length - 2].year);
    const [yearDisable, setYearDisable] = useState(true);
    console.log(years);

    const handleToggle = (toggled, id) => {
        setYearDisable(!yearDisable);
    };

    const handleFetchEarningData = async () => {
        try {
            setLoading(true);

            // Extract years using map
            const extractedYears = earningsData.totalEarnings.map((item) => item.year);
            setYears(extractedYears); // Store in state
        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        handleFetchEarningData();
    }, [])



    return (
        <div className="w-full relative flex flex-col gap-10">
            <div className="flex flex-col sm:flex-row items-center gap-4 w-full">
                <div className="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden rounded-full bg-gray-900">
                    <span className="font-medium text-[#17e080] text-xl">
                        <PiCrownSimpleBold />
                    </span>
                </div>

                <div className="flex flex-col flex-grow">
                    <span className="text-lg text-primary-7 font-semibold">
                        Royality Collected
                    </span>
                    <span className="text-sm font-medium text-primary-5">
                        Track earning, payments, and distribution efficiently
                    </span>
                </div>
                {/* <div className="flex items-end -mt-4">
                <Button />
              </div> */}
            </div>

            <section className='w-full flex gap-4 flex-col'>
                {/* Top bar */}
                <div className='flex justify-between w-full'>
                    <h2>
                        Total Anuual Royalities
                    </h2>
                    <div className='flex gap-2'>
                        <div className='flex items-center'>
                            <Toggle onToggle={handleToggle} id={"toggle2"} label={"Compare"}></Toggle>

                        </div>
                        <div className='flex items-center gap-2'>
                            <GloabalDropdown items={years}
                                disable={yearDisable}
                                selectedYear={selectedYear}
                                setSelectedYear={setSelectedYear}
                                initialYear={initialYear}
                                setInitialYear={setInitialYear} />-
                            <GloabalDropdown items={years}
                                disable={yearDisable}
                                secondYear={secondYear}
                                setSecondYear={setSecondYear} />
                        </div>
                    </div>
                </div>

                {/* annual reviews */}
                <div className='w-full flex flex-row gap-6'>
                    {/* card1 */}
                    <AnnualTotalEarning data={earningsData} selectedYear={selectedYear}
                        onYearChange={(year) => console.log(`Selected year: ${year}`)} />
                    {/* middle cards */}
                    <div className='flex flex-col justify-between gap-2'>
                        <HighestEarningCard />
                        <LowestEarningCard />
                    </div>

                    {/* card 3 */}
                    <EarningByPlatformCard />
                </div>

                {/* yearly graph */}
                <div className='w-[100%]'>
                    <YearlyComparisonChart
                        disable={yearDisable}
                        initialYear={initialYear}
                        secondYear={secondYear}
                        earningsData={earningsData}
                    />
                </div>

                {/* table */}
                <div className='w-[100%]'>
                    <div className='flex justify-between'>
                        <h2 className='text-2xl'>Performance</h2>
                        <div className="flex justify-center my-4">
                            <div className="flex space-x-6">
                                {tabs.map((tab, index) => (
                                    <div
                                        key={index}
                                        className={`relative pb-2 cursor-pointer font-semibold text-xl ${activeTab === tab
                                                ? "text-black"
                                                : "text-gray-300 hover:text-gray-400"
                                            }`}
                                        onClick={() => setActiveTab(tab)}
                                    >
                                        {tab}
                                        {activeTab === tab && (
                                            <div className="absolute left-0 right-0 h-[3.5px] bg-black bottom-0 rounded-full"></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='w-full border rounded-xl flex flex-col md:flex-nowrap flex-wrap'>
                        <div className='w-full flex flex-row md:flex-nowrap flex-wrap px-4 py-4 gap-2'>
                            <SearchBar />
                            <div className='flex flex-wrap gap-2'>
                                <GlobalDropdown placeholder='Best Revenue'
                                    width={150}
                                    customBorderWidth={2}
                                    customBorderColor="#a1aebf"
                                    dropdownHeight={40}
                                />
                                <Pagination />
                            </div>
                        </div>
                        <PerformanceTable data={TableData} loading={loading}/>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default RoyalityCollected