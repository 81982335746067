
import { CardContent } from '@mui/material';
import { Card } from 'flowbite-react';
import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';



const YearlyComparisonChart = (
    {
        earningsData,
        disable,
        initialYear,
        secondYear, }) => {
    // const earningsData = {
    //     monthlyEarnings: [
    //         {
    //             year: 2023,
    //             months: [
    //                 { month: "Jan", earnings: 900 },
    //                 { month: "Feb", earnings: 950 },
    //                 { month: "Mar", earnings: 1000 },
    //                 { month: "Apr", earnings: 975 },
    //                 { month: "May", earnings: 925 },
    //                 { month: "Jun", earnings: 950 },
    //                 { month: "Jul", earnings: 975 },
    //                 { month: "Aug", earnings: 1000 },
    //                 { month: "Sep", earnings: 950 },
    //                 { month: "Oct", earnings: 925 },
    //                 { month: "Nov", earnings: 975 },
    //                 { month: "Dec", earnings: 975 }
    //             ]
    //         },
    //         {
    //             year: 2024,
    //             months: [
    //                 { month: "Jan", earnings: 950 },
    //                 { month: "Feb", earnings: 1000 },
    //                 { month: "Mar", earnings: 1050 },
    //                 { month: "Apr", earnings: 1025 },
    //                 { month: "May", earnings: 975 },
    //                 { month: "Jun", earnings: 1000 },
    //                 { month: "Jul", earnings: 1025 },
    //                 { month: "Aug", earnings: 1050 },
    //                 { month: "Sep", earnings: 1000 },
    //                 { month: "Oct", earnings: 975 },
    //                 { month: "Nov", earnings: 1000 },
    //                 { month: "Dec", earnings: 950 }
    //             ]
    //         }
    //     ]
    // };

    // Get data for 2024 and 2023
    const year1 = earningsData.monthlyEarnings.find(item => item.year === initialYear);
    const year2 = earningsData.monthlyEarnings.find(item => item.year === secondYear);

    // Transform data into the format needed for the chart
    const chartData = year1.months.map((month, index) => ({
        month: month.month,
        current: month.earnings,
        previous: year2.months[index].earnings
    }));

    // Custom tooltip formatter
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-white p-2 border border-gray-200 shadow-sm">
                    <p className="text-sm font-medium">{label}</p>
                    <p className="text-sm text-gray-600">{initialYear}: £{payload[0]?.value?.toLocaleString()}</p>
                    {!disable &&

                        <p className="text-sm text-gray-600">{secondYear}: £{payload[1]?.value?.toLocaleString()}</p>
                    }
                </div>
            );
        }
        return null;
    };

    const renderGradients = () => (
        <defs>
            <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#4ade80" />
                <stop offset="100%" stopColor="#22c55e" stopOpacity="0.1"/>
            </linearGradient>
            <linearGradient id="purpleGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#a855f7" />
                <stop offset="100%" stopColor="#a855f7" stopOpacity="0.1" />
            </linearGradient>
        </defs>
    );

    return (
        <Card className="w-full ">

            <h1 className='text-2xl'>Yearly revenue</h1>

            <CardContent>
                <div className="h-80">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            data={chartData}
                            margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
                            barGap={0}
                        >
                            {renderGradients()}
                            <CartesianGrid vertical={false} stroke="#e5e7eb" />
                            <XAxis
                                dataKey="month"
                                // axisLine={false}
                                tickLine={false}
                                tick={{ fill: '#6b7280' }}
                            />
                            <YAxis
                                axisLine={false}
                                tickLine={false}
                                tickFormatter={(value) => `£${value / 1000}k`}
                                tick={{ fill: '#6b7280' }}
                            />
                            <Tooltip content={<CustomTooltip />} />
                            <Bar
                                dataKey="current"
                                fill="url(#greenGradient)"
                                radius={[0, 0, 0, 0]}
                                
                                
                            />
                            {!disable &&

                                <Bar
                                    dataKey="previous"
                                    fill="url(#purpleGradient)"
                                    radius={[0, 0, 0, 0]}
                                />
                            }
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                {/* <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-center justify-between">
                        <div>
                            <div>Dec {initialYear} £{year1.months[11].earnings.toLocaleString()}</div>
                            <div>Dec {secondYear} £{year2.months[11].earnings.toLocaleString()}</div>
                        </div>
                        <div className="text-emerald-500 flex items-center">
                            <span className="mr-1">↗</span>
                            {((year1.months[11].earnings - year2.months[11].earnings) / year2.months[11].earnings * 100).toFixed(0)}% annual growth
                        </div>
                    </div>
                </div> */}
            </CardContent>
        </Card>
    );
}

export default YearlyComparisonChart