import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(window.innerWidth > 640); // Default isOpen to false for small screens
  const myToken = localStorage.getItem("token");
  const [sidebarWidth, setSidebarWidth] = useState('15%'); // Default sidebar width for small screens
  const [mainContainerWidth, setMainContainerWidth] = useState('85%'); // Default for small screens
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!myToken) {
      navigate("/");
    }
  }, [myToken]);
  
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  
  // Update layout based on screen width
  useEffect(() => {
    const updateLayout = () => {
      const screenWidth = window.innerWidth;
  
      if (screenWidth <= 640) { // Small screens (e.g., mobile)
        setSidebarWidth(isOpen ? '50%' : '15%');
        setMainContainerWidth(isOpen ? '50%' : '85%'); // Adjust main container width
      } else if (screenWidth <= 1024) { // Medium screens (e.g., tablets)
        setSidebarWidth(isOpen ? '25%' : '10%');
        setMainContainerWidth(isOpen ? '75%' : '90%');
      } else { // Large screens (e.g., desktops)
        setSidebarWidth(isOpen ? '20%' : '5%');
        setMainContainerWidth(isOpen ? '80%' : '95%');
      }
    };
  
    updateLayout();  // Initial layout calculation
    window.addEventListener('resize', updateLayout); // Update layout on window resize
  
    return () => {
      window.removeEventListener('resize', updateLayout); // Cleanup event listener
    };
  }, [isOpen]); // Re-run the effect when `isOpen` changes
  

  return (
    <>
      <div className="layout-container">
        <div
          className={`  sidebar-container`}
          style={{
            minWidth: sidebarWidth,
            zIndex: "100",
            ...(window.innerWidth < 640 && isOpen === true ? { position: "absolute" } : {})
          }}
        >
          <Sidebar
            isOpen={isOpen}
            toggleSidebar={toggleSidebar}
            sidebarWidth={sidebarWidth}
          />
        </div>
        <main
          className={` main-container `}
          style={{ minWidth: mainContainerWidth }}
        >
          <Topbar />
          <div className="mx-auto px-6 py-8">{children}</div>
        </main>
      </div>
    </>
  );
};

export default Layout;
