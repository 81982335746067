import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Declineicon, Growthicon } from '../../../../components/icons'

const EarningByPlatformCard = ({ earningData, weeklyLoading }) => {
    const calculatePercentageChange = (current, previous) => {
        const change = ((current - previous) / previous) * 100;
        return change > 100 ? 100 : change;
    };

    const calculatePercentageOfTotal = (platformSales, totalSales) => {
        return (platformSales / totalSales) * 100;
    };

    const salesData = [
        {
            name: "Deliveroo",
            keyname: "deliveroo",
            amount: parseFloat(earningData?.deliveroo?.totalSales || 0),
            previousweekSales: parseFloat(earningData?.deliveroo?.previousweekSales || 0),
            percentage: earningData?.deliveroo?.previousweekSales
                ? calculatePercentageChange(
                    parseFloat(earningData?.deliveroo?.totalSales),
                    parseFloat(earningData?.deliveroo?.previousweekSales)
                )
                : 0,
            imgSrc: "deliveroo.png",
        },
        {
            name: "JustEat",
            keyname: "justeat",
            amount: parseFloat(earningData?.justeat?.totalSales || 0),
            previousweekSales: parseFloat(earningData?.justeat?.previousweekSales || 0),
            percentage: earningData?.justeat?.previousweekSales
                ? calculatePercentageChange(
                    parseFloat(earningData?.justeat?.totalSales),
                    parseFloat(earningData?.justeat?.previousweekSales)
                )
                : 0,
            imgSrc: "justeat.png",
        },
        {
            name: "Uber Eats",
            keyname: "uber",
            amount: parseFloat(earningData?.uber?.totalSales || 0),
            previousweekSales: parseFloat(earningData?.uber?.previousweekSales || 0),
            percentage: earningData?.uber?.previousweekSales
                ? calculatePercentageChange(
                    parseFloat(earningData?.uber?.totalSales),
                    parseFloat(earningData?.uber?.previousweekSales)
                )
                : 0,
            imgSrc: "ubereats.png",
        },
    ];

    const totalSales = salesData
        .reduce((total, item) => total + item.amount, 0)
        .toFixed(2);


    return (
        <div className="w-[500px] shadow-[0px_4px_10px_rgba(0,0,0,0.15)] rounded-xl px-4 py-6">
            {weeklyLoading ? (
                <>
                    <div className="flex gap-1 items-center mb-4">
                        <Skeleton width={100} height={24} />
                        <Skeleton width={100} height={10} />
                    </div>
                    <div className="mt-4">
                        {[...Array(3)].map((_, i) => (
                            <div
                                className="grid grid-cols-3 items-center gap-14 mb-4"
                                key={i}
                            >
                                <div className="flex items-center col-span-1">
                                    <Skeleton circle={true} width={24} height={24} />
                                    <Skeleton width={80} className="ml-2" />
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-1.5 col-span-1"></div>
                                <Skeleton width={80} height={16} className="col-span-1" />
                            </div>
                        ))}
                    </div>
                </>) : (

                <>
                    <h3 className="text-gray-400 font-medium text-xl">Earning by platform</h3>
                    {/* <div className="flex gap-1 items-center mt-[6px]">

                        {isGrowth ? <Growthicon /> : <Declineicon />}
                        <p
                            className={`font-semibold text-xs ${isGrowth ? "text-green-500" : "text-red-500"
                                }`}
                        >
                            {isGrowth ? "+" : "-"}
                            {displayPercentage}% vs. last week
                        </p>
                    </div> */}
                    <div className="mt-4">
                        {salesData.map((data) => (
                            <div
                                className="flex items-center gap-4 mb-4"
                                key={data.name}
                            >
                                <div className="flex items-center ">
                                    <img
                                        src={data.imgSrc}
                                        alt={data.name}
                                        className="w-8 h-8 mr-2"
                                    />
                                    <span className="text-sm font-semibold">{data.name}</span>
                                </div>
                                <div className="w-[200px] bg-gray-200 rounded-full h-1.5 relative">
                                    <div
                                        className="bg-green-500 h-1.5 rounded-full absolute"
                                        style={{
                                            width: `${calculatePercentageOfTotal(
                                                data.amount,
                                                totalSales
                                            )}%`,
                                        }}
                                    ></div>
                                </div>
                                <span className="font-semibold">
                                    £{parseInt(data.amount).toLocaleString()}
                                </span>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>


    )
}

export default EarningByPlatformCard