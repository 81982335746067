import React, { useState, useEffect } from "react";
import CustomDropdownCheckbox from "../../../components/global-components/CustomDropdownChekbox";
import { Motorcycle } from "../../../components/icons";
import MultiCardCarousel from "../../../components/global-components/MultiCardCarousel";
import CustomDropdownCheckboxSkeleton from "../../../components/skelaton/DrodownSkelton";

const CustomerReviews = ({
  dailyOperationReviewData,
  onSortChange,
  loading,
}) => {
  const [dropdownSortValue, setDropdownSortValue] = useState(["all"]);
  // console.warn(dailyOperationReviewData)

  const filteredOptions = dailyOperationReviewData?.check
    ? Object.keys(dailyOperationReviewData?.check)
        .filter((key) => dailyOperationReviewData.check[key])
        .map((key) => ({
          value: key,
          label: key.charAt(0).toUpperCase() + key.slice(1),
        }))
    : [];

  const handleSelectionChange = (selectedValues) => {
    const sortValues = selectedValues.length > 0 ? selectedValues : [];
    setDropdownSortValue(sortValues);
    onSortChange(sortValues);
  };

  useEffect(() => {
    handleSelectionChange(["all"]);
  }, []);

  return (
    <>
      <div className="bg-white w-full p-3 rounded-lg shadow-md mt-6">
        <div className="w-full pl-[13px] flex justify-between">
          <h2 className="text-xl font-[600] mb-4">Customer reviews</h2>
          <div className="flex justify-end items-center flex-col mb-4 pr-[18px] sm:flex sm:flex-row">
            <p className="text-center text-nowrap mr-2">{<Motorcycle />}</p>
            <label
              htmlFor="sort"
              className="text-center text-nowrap text-primary-6 mr-2"
            >
              Delivery app
              {/* {JSON.stringify(filteredOptions)} */}
            </label>

            <CustomDropdownCheckbox
              defaultSelectAll={filteredOptions.length ? true : false}
              options={filteredOptions}
              customBorderColor={"#A1AEBF"}
              customBorderRadius={2}
              customBorderWidth={2}
              menuHeight={200}
              menuWidth={160}
              onSelectionChange={handleSelectionChange}
            />
          </div>
        </div>
        <div className="p-[15px]">
          <MultiCardCarousel
            reviewData={dailyOperationReviewData?.data || []}
            loading={false}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerReviews;
