export const earningsData = {
    title: "Annual Earnings Overview",
    totalEarnings: [
        { year: 2000, earnings: 5000 },
        { year: 2001, earnings: 20200 },
        { year: 2002, earnings: 18400 },
        { year: 2003, earnings: 18800 },
        { year: 2004, earnings: 18000 },
        { year: 2005, earnings: 6200 },
        { year: 2006, earnings: 6400 },
        { year: 2007, earnings: 6800 },
        { year: 2008, earnings: 3000 },
        { year: 2009, earnings: 4200 },
        { year: 2010, earnings: 7500 },
        { year: 2011, earnings: 7700 },
        { year: 2012, earnings: 9000 },
        { year: 2013, earnings: 8300 },
        { year: 2014, earnings: 8500 },
        { year: 2015, earnings: 8800 },
        { year: 2016, earnings: 2000 },
        { year: 2017, earnings: 5200 },
        { year: 2018, earnings: 9500 },
        { year: 2019, earnings: 6800 },
        { year: 2020, earnings: 20000 },
        { year: 2021, earnings: 10500 },
        { year: 2022, earnings: 11000 },
        { year: 2023, earnings: 11500 },
        { year: 2024, earnings: 12000 },
        { year: 2025, earnings: 32000 },
    ],
    monthlyEarnings: [
        {
            year: 2000,
            months: [
                { month: "Jan", earnings: 400 },
                { month: "Feb", earnings: 380 },
                { month: "Mar", earnings: 420 },
                { month: "Apr", earnings: 450 },
                { month: "May", earnings: 425 },
                { month: "Jun", earnings: 415 },
                { month: "Jul", earnings: 435 },
                { month: "Aug", earnings: 445 },
                { month: "Sep", earnings: 410 },
                { month: "Oct", earnings: 405 },
                { month: "Nov", earnings: 390 },
                { month: "Dec", earnings: 425 }
            ]
        },
        {
            year: 2001,
            months: [
                { month: "Jan", earnings: 1500 },
                { month: "Feb", earnings: 1600 },
                { month: "Mar", earnings: 1700 },
                { month: "Apr", earnings: 1800 },
                { month: "May", earnings: 1750 },
                { month: "Jun", earnings: 1650 },
                { month: "Jul", earnings: 1700 },
                { month: "Aug", earnings: 1800 },
                { month: "Sep", earnings: 1750 },
                { month: "Oct", earnings: 1650 },
                { month: "Nov", earnings: 1600 },
                { month: "Dec", earnings: 1700 }
            ]
        },
        {
            year: 2002,
            months: [
                { month: "Jan", earnings: 1450 },
                { month: "Feb", earnings: 1550 },
                { month: "Mar", earnings: 1600 },
                { month: "Apr", earnings: 1550 },
                { month: "May", earnings: 1500 },
                { month: "Jun", earnings: 1525 },
                { month: "Jul", earnings: 1575 },
                { month: "Aug", earnings: 1600 },
                { month: "Sep", earnings: 1525 },
                { month: "Oct", earnings: 1500 },
                { month: "Nov", earnings: 1475 },
                { month: "Dec", earnings: 1550 }
            ]
        },
        {
            year: 2003,
            months: [
                { month: "Jan", earnings: 1500 },
                { month: "Feb", earnings: 1600 },
                { month: "Mar", earnings: 1650 },
                { month: "Apr", earnings: 1600 },
                { month: "May", earnings: 1550 },
                { month: "Jun", earnings: 1575 },
                { month: "Jul", earnings: 1625 },
                { month: "Aug", earnings: 1650 },
                { month: "Sep", earnings: 1575 },
                { month: "Oct", earnings: 1550 },
                { month: "Nov", earnings: 1525 },
                { month: "Dec", earnings: 1600 }
            ]
        },
        {
            year: 2004,
            months: [
                { month: "Jan", earnings: 1400 },
                { month: "Feb", earnings: 1500 },
                { month: "Mar", earnings: 1550 },
                { month: "Apr", earnings: 1500 },
                { month: "May", earnings: 1450 },
                { month: "Jun", earnings: 1475 },
                { month: "Jul", earnings: 1525 },
                { month: "Aug", earnings: 1550 },
                { month: "Sep", earnings: 1475 },
                { month: "Oct", earnings: 1450 },
                { month: "Nov", earnings: 1425 },
                { month: "Dec", earnings: 1500 }
            ]
        },
        {
            year: 2005,
            months: [
                { month: "Jan", earnings: 500 },
                { month: "Feb", earnings: 525 },
                { month: "Mar", earnings: 550 },
                { month: "Apr", earnings: 525 },
                { month: "May", earnings: 500 },
                { month: "Jun", earnings: 515 },
                { month: "Jul", earnings: 535 },
                { month: "Aug", earnings: 550 },
                { month: "Sep", earnings: 525 },
                { month: "Oct", earnings: 500 },
                { month: "Nov", earnings: 485 },
                { month: "Dec", earnings: 490 }
            ]
        },
        {
            year: 2006,
            months: [
                { month: "Jan", earnings: 525 },
                { month: "Feb", earnings: 550 },
                { month: "Mar", earnings: 575 },
                { month: "Apr", earnings: 550 },
                { month: "May", earnings: 525 },
                { month: "Jun", earnings: 530 },
                { month: "Jul", earnings: 545 },
                { month: "Aug", earnings: 560 },
                { month: "Sep", earnings: 535 },
                { month: "Oct", earnings: 510 },
                { month: "Nov", earnings: 495 },
                { month: "Dec", earnings: 500 }
            ]
        },
        {
            year: 2007,
            months: [
                { month: "Jan", earnings: 550 },
                { month: "Feb", earnings: 575 },
                { month: "Mar", earnings: 600 },
                { month: "Apr", earnings: 575 },
                { month: "May", earnings: 550 },
                { month: "Jun", earnings: 565 },
                { month: "Jul", earnings: 585 },
                { month: "Aug", earnings: 600 },
                { month: "Sep", earnings: 575 },
                { month: "Oct", earnings: 550 },
                { month: "Nov", earnings: 535 },
                { month: "Dec", earnings: 540 }
            ]
        },
        {
            year: 2008,
            months: [
                { month: "Jan", earnings: 250 },
                { month: "Feb", earnings: 275 },
                { month: "Mar", earnings: 300 },
                { month: "Apr", earnings: 275 },
                { month: "May", earnings: 250 },
                { month: "Jun", earnings: 235 },
                { month: "Jul", earnings: 245 },
                { month: "Aug", earnings: 260 },
                { month: "Sep", earnings: 235 },
                { month: "Oct", earnings: 225 },
                { month: "Nov", earnings: 220 },
                { month: "Dec", earnings: 230 }
            ]
        },
        {
            year: 2009,
            months: [
                { month: "Jan", earnings: 350 },
                { month: "Feb", earnings: 375 },
                { month: "Mar", earnings: 400 },
                { month: "Apr", earnings: 375 },
                { month: "May", earnings: 350 },
                { month: "Jun", earnings: 335 },
                { month: "Jul", earnings: 345 },
                { month: "Aug", earnings: 360 },
                { month: "Sep", earnings: 335 },
                { month: "Oct", earnings: 325 },
                { month: "Nov", earnings: 320 },
                { month: "Dec", earnings: 330 }
            ]
        },
        {
            year: 2010,
            months: [
                { month: "Jan", earnings: 600 },
                { month: "Feb", earnings: 625 },
                { month: "Mar", earnings: 650 },
                { month: "Apr", earnings: 625 },
                { month: "May", earnings: 600 },
                { month: "Jun", earnings: 615 },
                { month: "Jul", earnings: 635 },
                { month: "Aug", earnings: 650 },
                { month: "Sep", earnings: 625 },
                { month: "Oct", earnings: 600 },
                { month: "Nov", earnings: 635 },
                { month: "Dec", earnings: 640 }
            ]
        },
        {
            year: 2011,
            months: [
                { month: "Jan", earnings: 625 },
                { month: "Feb", earnings: 650 },
                { month: "Mar", earnings: 675 },
                { month: "Apr", earnings: 650 },
                { month: "May", earnings: 625 },
                { month: "Jun", earnings: 640 },
                { month: "Jul", earnings: 660 },
                { month: "Aug", earnings: 675 },
                { month: "Sep", earnings: 650 },
                { month: "Oct", earnings: 625 },
                { month: "Nov", earnings: 610 },
                { month: "Dec", earnings: 615 }
            ]
        },
        {
            year: 2012,
            months: [
                { month: "Jan", earnings: 725 },
                { month: "Feb", earnings: 750 },
                { month: "Mar", earnings: 775 },
                { month: "Apr", earnings: 750 },
                { month: "May", earnings: 725 },
                { month: "Jun", earnings: 740 },
                { month: "Jul", earnings: 760 },
                { month: "Aug", earnings: 775 },
                { month: "Sep", earnings: 750 },
                { month: "Oct", earnings: 725 },
                { month: "Nov", earnings: 760 },
                { month: "Dec", earnings: 765 }
            ]
        },
        {
            year: 2013,
            months: [
                { month: "Jan", earnings: 675 },
                { month: "Feb", earnings: 700 },
                { month: "Mar", earnings: 725 },
                { month: "Apr", earnings: 700 },
                { month: "May", earnings: 675 },
                { month: "Jun", earnings: 690 },
                { month: "Jul", earnings: 710 },
                { month: "Aug", earnings: 725 },
                { month: "Sep", earnings: 700 },
                { month: "Oct", earnings: 675 },
                { month: "Nov", earnings: 660 },
                { month: "Dec", earnings: 665 }
            ]
        },
        {
            year: 2014,
            months: [
                { month: "Jan", earnings: 700 },
                { month: "Feb", earnings: 725 },
                { month: "Mar", earnings: 750 },
                { month: "Apr", earnings: 725 },
                { month: "May", earnings: 700 },
                { month: "Jun", earnings: 715 },
                { month: "Jul", earnings: 735 },
                { month: "Aug", earnings: 750 },
                { month: "Sep", earnings: 725 },
                { month: "Oct", earnings: 700 },
                { month: "Nov", earnings: 685 },
                { month: "Dec", earnings: 690 }
            ]
        },
        {
            year: 2015,
            months: [
                { month: "Jan", earnings: 725 },
                { month: "Feb", earnings: 750 },
                { month: "Mar", earnings: 775 },
                { month: "Apr", earnings: 750 },
                { month: "May", earnings: 725 },
                { month: "Jun", earnings: 740 },
                { month: "Jul", earnings: 760 },
                { month: "Aug", earnings: 775 },
                { month: "Sep", earnings: 750 },
                { month: "Oct", earnings: 725 },
                { month: "Nov", earnings: 710 },
                { month: "Dec", earnings: 715 }
            ]
        },
        {
            year: 2016,
            months: [
                { month: "Jan", earnings: 150 },
                { month: "Feb", earnings: 175 },
                { month: "Mar", earnings: 200 },
                { month: "Apr", earnings: 175 },
                { month: "May", earnings: 150 },
                { month: "Jun", earnings: 165 },
                { month: "Jul", earnings: 185 },
                { month: "Aug", earnings: 200 },
                { month: "Sep", earnings: 175 },
                { month: "Oct", earnings: 150 },
                { month: "Nov", earnings: 135 },
                { month: "Dec", earnings: 140 }
            ]
        },
        {
            year: 2017,
            months: [
                { month: "Jan", earnings: 425 },
                { month: "Feb", earnings: 450 },
                { month: "Mar", earnings: 475 },
                { month: "Apr", earnings: 450 },
                { month: "May", earnings: 425 },
                { month: "Jun", earnings: 440 },
                { month: "Jul", earnings: 460 },
                { month: "Aug", earnings: 475 },
                { month: "Sep", earnings: 450 },
                { month: "Oct", earnings: 425 },
                { month: "Nov", earnings: 410 },
                { month: "Dec", earnings: 415 }
            ]
        },
        {
            year: 2018,
            months: [
                { month: "Jan", earnings: 425 },
                { month: "Feb", earnings: 450 },
                { month: "Mar", earnings: 475 },
                { month: "Apr", earnings: 450 },
                { month: "May", earnings: 425 },
                { month: "Jun", earnings: 440 },
                { month: "Jul", earnings: 460 },
                { month: "Aug", earnings: 475 },
                { month: "Sep", earnings: 450 },
                { month: "Oct", earnings: 425 },
                { month: "Nov", earnings: 410 },
                { month: "Dec", earnings: 415 }
            ]
        },
        {
            year: 2019,
            months: [
                { month: "Jan", earnings: 425 },
                { month: "Feb", earnings: 450 },
                { month: "Mar", earnings: 475 },
                { month: "Apr", earnings: 450 },
                { month: "May", earnings: 425 },
                { month: "Jun", earnings: 440 },
                { month: "Jul", earnings: 460 },
                { month: "Aug", earnings: 475 },
                { month: "Sep", earnings: 450 },
                { month: "Oct", earnings: 425 },
                { month: "Nov", earnings: 410 },
                { month: "Dec", earnings: 415 }
            ]
        },
        {
            year: 2020,
            months: [
                { month: "Jan", earnings: 425 },
                { month: "Feb", earnings: 450 },
                { month: "Mar", earnings: 475 },
                { month: "Apr", earnings: 450 },
                { month: "May", earnings: 425 },
                { month: "Jun", earnings: 440 },
                { month: "Jul", earnings: 460 },
                { month: "Aug", earnings: 475 },
                { month: "Sep", earnings: 450 },
                { month: "Oct", earnings: 425 },
                { month: "Nov", earnings: 410 },
                { month: "Dec", earnings: 415 }
            ]
        },
        {
            year: 2021,
            months: [
                { month: "Jan", earnings: 425 },
                { month: "Feb", earnings: 450 },
                { month: "Mar", earnings: 475 },
                { month: "Apr", earnings: 450 },
                { month: "May", earnings: 425 },
                { month: "Jun", earnings: 440 },
                { month: "Jul", earnings: 460 },
                { month: "Aug", earnings: 475 },
                { month: "Sep", earnings: 450 },
                { month: "Oct", earnings: 425 },
                { month: "Nov", earnings: 410 },
                { month: "Dec", earnings: 415 }
            ]
        },
        {
            year: 2022,
            months: [
                { month: "Jan", earnings: 425 },
                { month: "Feb", earnings: 450 },
                { month: "Mar", earnings: 475 },
                { month: "Apr", earnings: 450 },
                { month: "May", earnings: 425 },
                { month: "Jun", earnings: 440 },
                { month: "Jul", earnings: 460 },
                { month: "Aug", earnings: 475 },
                { month: "Sep", earnings: 450 },
                { month: "Oct", earnings: 425 },
                { month: "Nov", earnings: 410 },
                { month: "Dec", earnings: 415 }
            ]
        },

        // Adding a few more recent years as examples
        {
            year: 2023,
            months: [
                { month: "Jan", earnings: 900 },
                { month: "Feb", earnings: 950 },
                { month: "Mar", earnings: 1000 },
                { month: "Apr", earnings: 975 },
                { month: "May", earnings: 925 },
                { month: "Jun", earnings: 950 },
                { month: "Jul", earnings: 975 },
                { month: "Aug", earnings: 1000 },
                { month: "Sep", earnings: 950 },
                { month: "Oct", earnings: 925 },
                { month: "Nov", earnings: 975 },
                { month: "Dec", earnings: 975 }
            ]
        },
        {
            year: 2024,
            months: [
                { month: "Jan", earnings: 950 },
                { month: "Feb", earnings: 1000 },
                { month: "Mar", earnings: 1050 },
                { month: "Apr", earnings: 1025 },
                { month: "May", earnings: 975 },
                { month: "Jun", earnings: 1000 },
                { month: "Jul", earnings: 1025 },
                { month: "Aug", earnings: 1050 },
                { month: "Sep", earnings: 1000 },
                { month: "Oct", earnings: 975 },
                { month: "Nov", earnings: 1000 },
                { month: "Dec", earnings: 950 }
            ]
        },
        {
            year: 2025,
            months: [
                { month: "Jan", earnings: 2500 },
                { month: "Feb", earnings: 2600 },
                { month: "Mar", earnings: 2700 },
                { month: "Apr", earnings: 2750 },
                { month: "May", earnings: 2650 },
                { month: "Jun", earnings: 2700 },
                { month: "Jul", earnings: 2750 },
                { month: "Aug", earnings: 2800 },
                { month: "Sep", earnings: 2700 },
                { month: "Oct", earnings: 2650 },
                { month: "Nov", earnings: 2600 },
                { month: "Dec", earnings: 2600 }
            ]
        }
    ]
};

export const TableData = [
    {
        "location": "New York",
        "annualEarnings": 120000,
        "highestEarningMonth": {
            "month": "December",
            "earnings": 12000
        },
        "lowestEarningMonth": {
            "month": "February",
            "earnings": 6000
        },
        "rating": 4.8,
        "opScore": 92
    },
    {
        "location": "Los Angeles",
        "annualEarnings": 95000,
        "highestEarningMonth": {
            "month": "November",
            "earnings": 9500
        },
        "lowestEarningMonth": {
            "month": "January",
            "earnings": 4750
        },
        "rating": 4.6,
        "opScore": 89
    },
    {
        "location": "Chicago",
        "annualEarnings": 80000,
        "highestEarningMonth": {
            "month": "June",
            "earnings": 8000
        },
        "lowestEarningMonth": {
            "month": "September",
            "earnings": 4000
        },
        "rating": 4.7,
        "opScore": 88
    },
    {
        "location": "San Francisco",
        "annualEarnings": 150000,
        "highestEarningMonth": {
            "month": "October",
            "earnings": 15000
        },
        "lowestEarningMonth": {
            "month": "March",
            "earnings": 7500
        },
        "rating": 4.9,
        "opScore": 95
    },
    {
        "location": "Dallas",
        "annualEarnings": 70000,
        "highestEarningMonth": {
            "month": "May",
            "earnings": 7000
        },
        "lowestEarningMonth": {
            "month": "August",
            "earnings": 3500
        },
        "rating": 4.5,
        "opScore": 84
    },
    {
        "location": "Miami",
        "annualEarnings": 85000,
        "highestEarningMonth": {
            "month": "April",
            "earnings": 8500
        },
        "lowestEarningMonth": {
            "month": "November",
            "earnings": 4250
        },
        "rating": 4.6,
        "opScore": 87
    },
    {
        "location": "Seattle",
        "annualEarnings": 110000,
        "highestEarningMonth": {
            "month": "July",
            "earnings": 11000
        },
        "lowestEarningMonth": {
            "month": "February",
            "earnings": 5500
        },
        "rating": 4.8,
        "opScore": 91
    },
    {
        "location": "Atlanta",
        "annualEarnings": 75000,
        "highestEarningMonth": {
            "month": "August",
            "earnings": 7500
        },
        "lowestEarningMonth": {
            "month": "December",
            "earnings": 3750
        },
        "rating": 4.4,
        "opScore": 83
    }
]
