import React, { useState, useRef, useEffect } from "react";
import { Notification, UserIcon } from "../icons";
import { FaSignOutAlt, FaKey, FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useCallPostMutation } from "../../services/api";
import decodeToken from "../../utils/decodeToken";
import { toast } from "react-toastify";

const Topbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userId, setUserId] = useState(null);
  const [tkenData, setTokenData] = useState({});
  const modalRef = useRef(null);
  const passwordModalRef = useRef(null);
  const navigate = useNavigate();
  const [postApi] = useCallPostMutation();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const togglePasswordModal = () => {
    setIsPasswordModalOpen(!isPasswordModalOpen);
    setIsModalOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    // navigate("/");
    window.location.reload()
  };


  useEffect(() => {
    const token = localStorage.getItem("token");
    const userdata = decodeToken(token);
    console.log({ userdata })
    setUserId(userdata?.user_id);
    setTokenData(userdata)
  }, [])

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("No token found");
      return;
    }

    if (!currentPassword || !newPassword || !confirmPassword) {
      toast.error("All fields are required");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match");
      return;
    }

    const userdata = decodeToken(token);
    console.log({ userdata })
    setUserId(userdata?.user_id);
    setTokenData(userdata)

    try {
      const response = await postApi({
        url: "/updatepassword",
        body: {
          newPassword,
          userdata: {
            user_id: userdata?.user_id,
          },
        },
        // headers: {
        //   Authorization: token,
        // },
      });

      if (response.data.success) {
        toast.success("Password changed successfully");
        togglePasswordModal();
      } else {
        toast.error(response.data.message || "Failed to change password");
      }
    } catch (error) {
      toast.error("Error changing password: " + error.message);
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
    if (
      passwordModalRef.current &&
      !passwordModalRef.current.contains(event.target)
    ) {
      setIsPasswordModalOpen(false);
    }
  };

  useEffect(() => {
    if (isModalOpen || isPasswordModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen, isPasswordModalOpen]);

  return (
    <header className="bg-white  shadow-sm h-16 flex items-center justify-end z-20">
      <div className="flex items-center justify-end mr-4">
        <div className="relative">
          {tkenData?.user_id === 162  || tkenData?.user_id === 287 || tkenData?.user_id === 288 || tkenData?.user_id === 289 ? (<button className="relative z-10 block h-12 w-12 rounded-lg overflow-hidden focus:outline-none">
            <img
              className="h-full w-full object-cover"
              src="https://pbs.twimg.com/profile_images/1339614112754634757/GVL10RWS_400x400.jpg"
              alt="Your avatar"
            />
          </button>) :
          <button className="relative z-10 block h-10 w-10 rounded-lg overflow-hidden focus:outline-none">
            <img
              className="h-full w-full object-cover h-10 w-10"
              src="gk-logo.jpeg"
              // src="honor_logo.png"
              alt="Your avatar"

            />
          </button>
          }

        </div>

        <div className="flex items-center ml-4 gap-4">
          <div className="flex flex-col">
            <span className="text-black text-sm font-semibold">
              {tkenData?.username}
            </span>
            <span className="text-gray-500 text-base font-normal">
              {tkenData?.email}
            </span>
          </div>
          <div className="relative">
            <button onClick={toggleModal}>
              <UserIcon />
            </button>
            {isModalOpen && (
              <div
                ref={modalRef}
                className="absolute right-0 mt-8 w-48 bg-white p-6 rounded-lg shadow-lg z-50"
              >
                <button
                  className="flex items-center gap-2 text-blue-600 hover:text-blue-700 mb-4"
                  onClick={togglePasswordModal}
                >
                  <FaKey />
                  <span className="text-sm text-black">Change Password</span>
                </button>
                <button
                  className="flex items-center gap-2 text-red-600 hover:text-red-700"
                  onClick={() => {
                    toggleModal();
                    handleLogout();
                  }}
                >
                  <FaSignOutAlt />
                  <span className="text-sm">Logout</span>
                </button>
              </div>
            )}
          </div>
          {/* <button>
            <Notification />
          </button> */}
        </div>
      </div>

      {isPasswordModalOpen && (
        <div className="fixed inset-0   flex items-center justify-center z-50">
          <div
            ref={passwordModalRef}
            className="bg-white p-8 rounded-lg shadow-lg w-96"
          >
            <h2 className="text-2xl font-bold mb-4">Change Password</h2>
            <form onSubmit={handleChangePassword}>
              <div className="mb-4 relative">
                <label
                  htmlFor="currentPassword"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Current Password
                </label>
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  id="currentPassword"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  className="absolute right-3 top-8 text-gray-500"
                >
                  {showCurrentPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              </div>
              <div className="mb-4 relative">
                <label
                  htmlFor="newPassword"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  New Password
                </label>
                <input
                  type={showNewPassword ? "text" : "password"}
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  className="absolute right-3 top-8 text-gray-500"
                >
                  {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              </div>
              <div className="mb-6 relative">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Confirm New Password
                </label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-3 top-8 text-gray-500"
                >
                  {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={togglePasswordModal}
                  className="mr-4 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-full sm:w-40 bg-primary-7 border border-gray-300 rounded-lg h-10 text-white flex items-center justify-center gap-2"
                >
                  Change Password
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </header>
  );
};

export default Topbar;
