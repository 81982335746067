import React, { useEffect, useState } from "react";
import { PiReceiptBold } from "react-icons/pi";
import Pagination from "../../components/global-components/Pagination";
import CustomDropdownCheckbox from "../../components/global-components/CustomDropdownChekbox";
import CustomDropdownCheckboxSkeleton from "../../components/skelaton/DrodownSkelton";
import { LocationIcon, SadEmoji } from "../../components/icons";
import { useCallGetQuery, useCallPostMutation } from "../../services/api";
import ParentDatePicker from "../../components/global-components/CustomDatePicker/ParantDatePicker";
import { BsDownload } from "react-icons/bs";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const FinanceAndAdmin = () => {

  const checkboxStyle = `
  w-4 h-4 
  rounded 
  appearance-none
  border
  border-gray-300
  bg-white 
  checked:bg-[#fd7200] 
  checked:hover:bg-[#fd7200] 
  checked:focus:bg-[#fd7200] 
  focus:ring-0 
  focus:ring-offset-0 
  cursor-pointer 
  transition-colors 
  duration-200 
  relative
  [&:checked]:after:content-[''] 
  [&:checked]:after:absolute 
  [&:checked]:after:left-[4px] 
  [&:checked]:after:top-[1px] 
  [&:checked]:after:w-[6px] 
  [&:checked]:after:h-[10px] 
  [&:checked]:after:border-white 
  [&:checked]:after:border-r-2 
  [&:checked]:after:border-b-2 
  [&:checked]:after:transform 
  [&:checked]:after:rotate-45
`.replace(/\s+/g, ' ').trim();

  // const data = [
  //   { id: "1", startDate: "23/09/2024", endDate: "30/09/2024", location: "Aylesbury" },
  //   { id: "2", startDate: "16/09/2024", endDate: "30/09/2024", location: "Barmulloch" },
  //   { id: "3", startDate: "09/09/2024", endDate: "15/09/2024", location: "Beckenham" },
  //   { id: "4", startDate: "02/09/2024", endDate: "08/09/2024", location: "Bedford" },
  //   { id: "5", startDate: "26/08/2024", endDate: "01/08/2024", location: "Bexleyheath" },
  //   { id: "6", startDate: "19/08/2024", endDate: "25/08/2024", location: "Birkenhead" },
  //   { id: "7", startDate: "12/08/2024", endDate: "18/08/2024", location: "Bognor Regis" },
  //   { id: "8", startDate: "05/08/2024", endDate: "11/08/2024", location: "Bolton" },
  //   { id: "9", startDate: "29/07/2024", endDate: "04/07/2024", location: "Bournemouth" },
  //   { id: "10", startDate: "22/07/2024", endDate: "28/07/2024", location: "Brentwood" },
  //   { id: "11", startDate: "15/07/2024", endDate: "21/07/2024", location: "Bromley" },
  //   { id: "12", startDate: "08/07/2024", endDate: "14/07/2024", location: "Canary Wharf - Westferry Road" },
  // ];

  const [locationData, setLocationData] = useState([])
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectLocation, setSelectLocation] = useState(["all"]);
  const locationDate = useSelector((state) => state?.filter?.locationDates);
  const [postData, { isLoading: isPosting }] = useCallPostMutation();
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const ReduxSelectedLocations = useSelector(
    (state) => state.filter.selectedLocations
  );

  const handleFetchFinanceData = async () => {
    try {
      setLoading(true)
      const tableData = await postData({
        url: "reports",
        body: {
          WeekStart: locationData.start,
          pageSize: itemsPerPage === 0 ? "all" : itemsPerPage,
          page: currentPage,
          locations:selectLocation?.[0] === "all" ? "all" : selectLocation,
        }
      })
      console.log({tabledata1:tableData})
      if (!tableData?.data) {
        throw new Error('No data received from server');
      }
      // console.log({})
      setError(false);
      setTotalPages(tableData?.data?.totalPages)
      setData(tableData?.data?.data)
    } catch (error) {
      console.error(error.message)
      setError(true);
      setData([]);
    } finally {
      setLoading(false);
    }

  }

  console.log({tabledata2:data})

 
  

  useEffect(() => {
    handleFetchFinanceData();
    console.log({selectLocation})
  }, [locationDate, currentPage, itemsPerPage, selectLocation]);

  const GetDropDownData = useCallGetQuery("weekly-operations/get-location");
  const handleFetchLocation =  () => {
    try {
      const { data, error } =  GetDropDownData; // Assuming this is an async function

      if (error) {
        throw new Error(error.message || 'Failed to fetch location data');
      }

      if (data?.status) {
        const location = data?.data?.map((item) => ({
          value: item.Name,
          label: item.Name,
        }));

        // Sort the location array alphabetically by label
        location.sort((a, b) => a.label.localeCompare(b.label));

        setLocationData(location);

        // Dispatch the sorted data if needed
        // dispatch(setweeklyOperationsData(location));
      } else {
        // Handle case where the status is not true
        console.error('Error: Invalid status in response', data);
      }
    } catch (error) {
      // Log the error to the console or send it to an external logging service
      console.error('Error fetching location data:', error);
    }
  };

  useEffect(() => {
    // if (!GetDropDownData.isLoading) {
      handleFetchLocation();
    // }
  }, [GetDropDownData.isLoading, selectLocation]);


  console.log({ GetDropDownData })

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      // Create array of indices from 0 to data.length-1
      const allIndices = Array.from({ length: data.length }, (_, index) => index);
      setSelectedItems(allIndices);
    } else {
      setSelectedItems([]);
    }
  };

  // Modified to work with index instead of id
  const handleSelectItem = (index) => {
    let newSelectedItems;
    if (selectedItems.includes(index)) {
      // If index is already selected, remove it
      newSelectedItems = selectedItems.filter(item => item !== index);
    } else {
      // If index is not selected, add it
      newSelectedItems = [...selectedItems, index];
    }
    setSelectedItems(newSelectedItems);
    // Update selectAll checkbox state based on whether all items are selected
    setSelectAll(newSelectedItems.length === data.length);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleSelectionChange = (selectedValues, dropdownType) => {
    if (dropdownType === "location") {
      setSelectLocation(selectedValues);
      // dispatch(setSelectedLocation(selectedValues));
    }
  };

  const renderTableContent = () => {
    if (error) {
      return (
        <tr className="bg-gray-50">
          <td colSpan="6" className="px-4 py-12 text-center">
            <div className="flex flex-col items-center justify-center">
              <SadEmoji />
              <p className="font-semibold text-lg">Error loading data</p>
              <p className="text-gray-500 mt-2 text-sm">
                {error}. Please try again later.
              </p>
            </div>
          </td>
        </tr>
      );
    }

    if (loading) {
      return Array.from({ length: 10 }).map((_, index) => (
        <tr key={index} className="bg-white border-b hover:bg-gray-50">
          <td className="px-2 sm:px-4 py-2 sm:py-3 w-10">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden sm:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden md:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden lg:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden xl:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3">
            <Skeleton circle width={20} height={20} />
          </td>
        </tr>
      ));
    }

    if (!loading && data.length === 0) {
      return (
        <tr className="bg-gray-50 roboto-font-table">
          <td
            colSpan="6"
            className="px-2 sm:px-4 py-12 sm:py-24 text-center roboto-font-table "
          >
            <div className="flex flex-col items-center justify-center">
              <SadEmoji />
              <p className="text-gray-900 font-semibold text-lg sm:text-xl">
                No records found
              </p>
              <p className="text-gray-500 mt-2 text-sm sm:text-base roboto-font-table ">
                Try adjusting your search or filter to find what you're looking
                for.
              </p>
            </div>
          </td>
        </tr>
      );
    }

    return data.map((item, index) => (
      <tr key={index} className="hover:bg-gray-50">
        <td className="px-4 py-3">
          <input
            type="checkbox"
            checked={selectedItems.includes(index)}
            onChange={() => handleSelectItem(index)}
            className={checkboxStyle}
          />
        </td>
        <td className="px-4 py-3 text-sm text-gray-900">
          {item.weekStart} - {item.weekEnd}
        </td>
        <td className="px-4 py-3 text-sm text-gray-900">{item.external_name}</td>
        <td className="px-4 py-3">
          <button className="flex items-center gap-2">
            <span className="text-sm">Download</span>
            <BsDownload className="text-lg" />
          </button>
        </td>
        <td className="px-4 py-3">
          <button className="flex items-center gap-2">
            <span className="text-sm">Download</span>
            <BsDownload className="text-lg" />
          </button>
        </td>
      </tr>
    ));
  };



  return (
    <>
      <div className="w-full relative flex flex-col gap-10">
        <div className="flex flex-col sm:flex-row items-center gap-4 w-full">
          <div className="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden rounded-full bg-gray-900">
            <span className="font-medium text-[#17e080] text-xl">
              <PiReceiptBold />
            </span>
          </div>

          <div className="flex flex-col flex-grow">
            <span className="text-lg text-primary-7 font-semibold">
              Finance & Admin
            </span>
            <span className="text-sm font-medium text-primary-5">
              Manage and generate weekly reports effortlessly
            </span>
          </div>
          {/* <div className="flex items-end -mt-4">
            <Button />
          </div> */}
        </div>

        <div className="w-full border rounded-xl flex flex-col md:flex-nowrap flex-wrap">
          <div className="w-full flex flex-row md:flex-nowrap flex-wrap px-4 py-4 justify-between">
            <h2 className="text-2xl font-medium">Invoice & weekly reports</h2>
            <div className="flex flex-col">
              <div className="flex flex-row flex-wrap">
                <ParentDatePicker />

                <div className="flex flex-wrap items-end md:items-center justify-center md:justify-between gap-2">
                  <div className="flex items-center md:flex-row flex-col flex-wrap gap-2">
                    <div className="flex flex-row items-center gap-2">
                      <LocationIcon />
                      <span className="text-primary-6 text-base font-[600]">Location</span>
                    </div>
                    {locationData.length > 0 ? (
                      <CustomDropdownCheckbox
                        options={locationData}
                        onSelectionChange={(data) => {
                          handleSelectionChange(
                            locationData.length === data.length ? ["all"] : data,
                            "location"
                          );
                        }}
                        defaultSelectAll={ReduxSelectedLocations?.[0] === "all" ? 1 : 0}
                        selectAllText={"All"}
                        selectAllValue={"All"}
                        dropdownType={"location"}
                        width={137}
                        placeholder={"Select..."}
                        customBorderColor={"#A1AEBF"}
                        customBorderRadius={2}
                        customBorderWidth={2}
                        menuHeight={200}
                        menuWidth={160}
                        left={"-25px"}
                        activeValues={
                          ReduxSelectedLocations?.[0] === "all"
                            ? locationData
                            : ReduxSelectedLocations
                        }

                      //   activeValues={appliedFilter?.weeklyOperationsData || []}
                      // activeValues={["Winchester"]}
                      />
                    ) : (
                      <div className="m-3">
                        <CustomDropdownCheckboxSkeleton />
                      </div>
                    )}
                  </div>
                  <div>
                    <Pagination
                      totalPages={totalPages}
                      itemsPerPage={itemsPerPage}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                      onItemsPerPageChange={handleItemsPerPageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {selectedItems.length > 0 && (
            <div className="flex flex-row gap-4 px-4 py-2">
              <button className="flex items-center gap-2 text-[#fd7200]">
                <span>Download {selectedItems.length} reports</span>
                <BsDownload className="text-lg" />
              </button>
              <button className="flex items-center gap-2">
                <span>Download {selectedItems.length} invoices</span>
                <BsDownload className="text-lg" />
              </button>
            </div>
          )}

          {/* Table */}


          {/* Table section */}
          <div className="w-full h-[500px] overflow-y-auto">
            <table className="w-full border-t h-full">
              <thead className="bg-gray-50 border-b">
                <tr>
                  <th className="px-4 py-3 text-left w-10">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      className={checkboxStyle}
                    />
                  </th>
                  <th className="px-4 py-3 text-sm font-medium text-gray-500 text-left">Date</th>
                  <th className="px-4 py-3 text-sm font-medium text-gray-500 text-left">Location</th>
                  <th className="px-4 py-3 text-sm font-medium text-gray-500 text-left">Weekly Report</th>
                  <th className="px-4 py-3 text-sm font-medium text-gray-500 text-left">Invoice</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {renderTableContent()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};


